*,
html,
body,
a,
p,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
}
.btn__main {
  background: #f2b120 !important;
  color: #fff;
  border-color: #f2b120 !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  line-height: 22.5px;
  font-weight: 400;
  padding: 10px 35px !important;
}
.container-fluid {
  padding: 0 120px !important;
}
.Nav__wrapper {
  position: relative;
  z-index: 999;
  background: #00000080;
}
.Nav__wrapper .navbar-nav .nav-link.active {
  color: #f2b120 !important;
  font-weight: 700;
}
.Nav__wrapper .navbar-nav .nav-link.active:after {
  content: "";
  width: 50px;
  height: 4px;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(135deg, #f9ec7d 0%, #f5b100 100%);
  border-radius: 16px;
}
.Nav__wrapper .navbar-nav a {
  position: relative;
  padding: 0 20px !important;
  color: #fff !important;
  font-size: 16px;
  font-weight: 400;
}
.hp__banner {
  position: relative;
  top: -95px;
  margin-bottom: -90px;
}
.hp__banner img {
  width: 100%;
}
.quopte__wrapper {
  background: #101422;
  padding: 30px 30px 20px 80px;
  width: 550px;
  position: absolute;
  top: 20%;
  left: 10%;
  z-index: 99;
  border-radius: 30px;
}

.quopte__wrapper .start-address,
.quopte__wrapper .end-address {
  position: relative;
}
.quopte__wrapper .start-address:before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 5px solid #41689d;
  position: absolute;
  background: #fff;
  left: -35px;
  top: 15px;
}
.quopte__wrapper .end-address:after {
  content: "";
  border-left: 2px dashed #c7cace;
  height: 40px;
  width: 10px;
  position: absolute;
  left: -24px;
  top: -28px;
}
.quopte__wrapper .end-address:before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 5px solid #14b25c;
  position: absolute;
  background: #fff;
  left: -35px;
  top: 15px;
}
.quopte__wrapper img {
  width: auto;
  padding-right: 10px;
}
.quopte__wrapper .btn-group {
  margin-bottom: 20px !important;
}
.quopte__wrapper .btn-group label:nth-child(1) {
  width: 45%;
  margin-right: 10px;
}
.quopte__wrapper .btn-group label {
  border-radius: 10px !important;
  background: #f2b120;
  border: 1px solid #f2b120;
  padding: 15px 10px;
}
.quopte__wrapper .btn-group label:last-child {
  width: 45%;
  margin-left: 10px;
}
.quopte__wrapper .btn-group .btn-check[checked] + label {
  background: #0c4b85 !important;
}
.quopte__wrapper .form-control {
  border-radius: 6px !important;
}
.quopte__wrapper .btn-group {
  width: 100%;
}
.quopte__wrapper .input-group .form-control {
  border-radius: 6px 0 0 6px !important;
}
.quopte__wrapper .input-group-text {
  border-radius: 0 6px 6px 0 !important;
}
.hp__banner img {
  position: relative;
}
.hp__banner:after {
  content: "";
  background: url("../src/images/banner-vector.png");
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.hp__banner .banner__text {
  position: absolute;
  top: 30%;
  right: 5%;
  width: 46%;
  z-index: 99;
}
.hp__banner .banner__text h1 {
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 50px;
  font-size: 64px;
  font-weight: 700;
  line-height: 83px;
  letter-spacing: -0.02em;
}
.hp__banner .banner__text p {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
}
.hp__banner .banner__text h1 span {
  color: #ffddaa;
}
.section__gap {
  padding: 120px 0;
}
.purple__text {
  color: #f2b120;
}
.section__title span {
  color: #f2b120;
}

.section__title {
  color: #262626;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
}
.md__title {
  color: #0c4b85;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}
.small__title {
  color: #f2b120;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}
.dim__bg {
  background: #e8f4ff;
}
.pt-0 {
  padding-top: 0;
}

.threecolwithimage__wrapper .section__title {
  margin-bottom: 20px;
}
.threecolwithimage__wrapper p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #181818;
}
.threecolwithimage__wrapper .threecolwithimage__unit {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  box-shadow: 0px 3px 5px 0px #ddd;
  border-radius: 16px;
  padding: 30px;
  flex: 0 0 auto;
  width: 30.33333333%;
  margin: 0 18px;
}
.threecolwithimage__wrapper .threecolwithimage__unit img {
  width: 100%;
  height: 120px;
  object-fit: contain;
  margin-bottom: 30px;
}

.threecolwithimage__wrapper .threecolwithimage__unit h4 {
  text-transform: uppercase;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.009999999776482582px;
}

.threecolwithimage__wrapper .threecolwithimage__unit .rating__wrap i {
  background-color: #f2b120;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}
.threecolwithimage__wrapper .threecolwithimage__unit .rating__wrap {
  text-align: left !important;
}
.threecolwithimage__wrapper .threecolwithimage__unit h5 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.threecolwithimage__wrapper .threecolwithimage__unit .price-box {
  display: flex;
  justify-content: space-between;
}
.threecolwithimage__wrapper .threecolwithimage__unit p {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
}

.threecolwithimage__wrapper .threecolwithimagehover__unit:hover {
  background: #0f1321;
  transition: 0.6s ease all;
  color: #fff;
}
.threecolwithimage__wrapper .threecolwithimagehover__unit:hover p {
  transition: 0.6s ease all;
  color: #fff;
}
.threecolwithimage__wrapper .threecolwithimagehover__unit {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  border-radius: 10px;
  padding: 30px;
  flex: 0 0 auto;
  width: 30.33333333%;
  margin: 30px 18px 0 18px;
  padding: 30px 50px;
  transition: 0.6s ease all;
}
.threecolwithimage__wrapper .threecolwithimagehover__unit img {
  width: 100%;
  height: 120px;
  object-fit: contain;
  margin-bottom: 30px;
}

.threecolwithimage__wrapper .threecolwithimagehover__unit h4 {
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.009999999776482582px;
  margin-bottom: 15px;
}
.custom__media {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  height: 120px;
  padding: 10px;
  align-items: center;
}
.custom__media .left__part {
  background-color: #f7e8ff;
  padding: 20px;
  border-radius: 8px;
}
.custom__media .left__part img {
  width: 40px;
}
.custom__media .right__part p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

.custom__media:hover {
  box-shadow: 0px 20px 9px -14px #80808061;
  transition: 0.6s all ease;
}
.custom__media:hover .left__part {
  background-color: #f2b120;
  transition: 0.6s all ease;
}
.custom__media:hover .left__part img {
  filter: brightness(0) invert(1);
  transition: 0.6s all ease;
}

.custom__media__plain {
  display: flex;
  justify-content: start;
  gap: 30px;
  height: 120px;
  padding: 10px;
  align-items: center;
}
.custom__media__plain .left__part {
  padding: 20px;
  border-radius: 8px;
}
.custom__media__plain .left__part img {
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  background: #ff2663;
  object-fit: contain;
}
.custom__media__plain .right__part h3 {
  font-size: 20px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #3a3a3a;
}
.custom__media__plain .right__part p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}
.homepage-review .slick-vertical .slick-slide {
  min-height: 292px !important;
  margin-top: 20px;
}
.homepage-review .reviewer-info small {
  display: block;
}
.homepage-review .reviewer-info {
  text-align: left;
  height: 80px;
  gap: 30px;
  position: relative;
  top: -60px;

  left: 20px;
}
.homepage-review .slick-prev,
.homepage-review .slick-next {
  left: 15px;
  background: #fff;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 8px 0px #0c111f45;
  border-radius: 50% !important;
}
.homepage-review .slick-prev {
  top: 38%;
}
.homepage-review .slick-arrow:hover {
  background-color: #0c111f;
  color: #fff;
}
.homepage-review .slick-arrow:hover:before {
  color: #fff;
}
.homepage-review .slick-next {
  top: 62%;
}
.homepage-review .slick-next:before {
  content: "\f175";
  font-family: "fontawesome";
  color: #0c111f;
  font-size: 24px;
  opacity: 1;
}
.homepage-review .slick-slider {
  padding-left: 80px;
}
.homepage-review .slick-prev:before {
  content: "\f176";
  font-family: "fontawesome";
  color: #0c111f;
  font-size: 24px;
  opacity: 1;
}
.homepage-review .reviewer-info img {
  width: 80px !important;
}

.homepage-review {
  background: #f7f7f7;
}
.homepage-review p.text-left {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin-top: 20px;
  padding-left: 20px;
}

.homepage-review .reviewer-info h5 {
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-top: 15px;
  color: #0c111f;
}
.homepage-review .reviewer-info i {
  color: #f5b100;
}
.homepage-review .reviewitem {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.84);
  backdrop-filter: blur(11px);
}
.homepage-slider-desc .carousel-status {
  display: none;
}
.homepage-slider-desc .carousel .slide img {
  height: 500px;
  padding: 50px 0;
  object-fit: contain;
}
.control-dots,
.carousel-status {
  display: none;
}

footer {
  background: #0c111f;
  padding-top: 55px;
  padding-bottom: 10px;
  color: #fff;
}
footer img {
  margin-bottom: 20px;
}
footer p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

footer h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
footer .list-group-item {
  background-color: transparent;
  padding: 0;
  border: 0;
}
footer a,
footer a:hover {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  padding: 10px 0;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}

footer .input-group-text {
  background: #fcfcfc;
  border: 0;
  border-radius: 10px;
}
footer .form-control {
  border: 0;
  border-radius: 10px;
  background: #fcfcfc;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
footer .input-group-text i {
  color: #828282;
}
footer .copyright {
  color: #d3d3d3;
  padding-bottom: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .copyright a {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 50%;
  margin: 0 10px;
  height: 50px;
  width: 50px;
  display: inline-block;
}
footer .copyright i {
  font-size: 24px;
}
.quopte__wrapper form {
  padding: 20px;
}
.quopte__wrapper .form-select,
.quopte__wrapper .form-control {
  border: 0;
  border-radius: 0px;
  background: #ececec;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  height: 55px;
}

.quopte__wrapper .input-group-text {
  border: 0;
  border-radius: 0px;
}
.quopte__wrapper .input-group-text i {
  color: #828282;
}
.btn__gradient {
  border: 0 !important;
  height: 55px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(
    90.97deg,
    #ffcd83 0.17%,
    rgba(68, 34, 85, 0.67) 100%
  ) !important;
}

.quopte__wrapper .btn__main {
  margin: 0 auto;
  display: block;
  width: 300px;
}

.threecolwithimage__wrapper .nav-tabs {
  background: #f8f8f8;
  border-radius: 128px;
  text-align: center;
  display: inline-flex;
  padding: 20px;
  margin: 0 auto;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 40px;
  margin-bottom: 80px;
}
.threecolwithimage__wrapper .nav-tabs .nav-item button {
  color: #333;
}
.threecolwithimage__wrapper .nav-tabs .nav-item button.active {
  background: #f2b120;
  color: #fff;
  padding: 14px 32px;
  background: #f2b120;
  border-radius: 32px;
}
.btn__ghost {
  background: #ffffff !important;
  border: 1px solid #ffe12e !important;
  border-radius: 145px !important;
  color: #222;
}

.section__title.text-left {
  text-align: left;
}
.homepage-slider-desc .carousel .slider-wrapper {
  background: #0c111f;
}
.homepage-slider-desc .carousel-root {
  position: relative;
  left: -17%;
}
.homepage-more-about {
  padding: 40px 80px;
  background: linear-gradient(90deg, #0c111f -3.25%, #0c111f 101.19%);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 150px;
  position: relative;
}
.homepage-more-about p {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: 0.02em;
  text-align: left;
}
.homepage-more-about a {
  text-decoration: none;
}
.homepage-more-about i {
  font-size: 54px;
  text-align: right;
  color: #fff;
  display: block;
}
.homepage-review .carousel .slide {
  margin-top: 40px;
}
footer .suscribe-part {
  text-align: center;
  margin-bottom: 70px;
}
footer .suscribe-part p {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
footer:before {
  content: "";
  background: url("../src/images/banner-vector.png");
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.btn__rounded {
  border-radius: 40px !important;
  line-height: 32px;
  padding: 16px 40px !important;
  font-weight: 500 !important;
  color: #1b1b1b !important;
}
.form-check-label {
  color: #fff;
}

.homepage-more-about:after {
  content: "";
  background: url("../src/images/sm-vector.png");
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}
.inr__banner {
  position: relative;
  top: -100px;
  margin-bottom: -100px;
}
.inr__banner img {
  width: 100%;
}
.diamond-list .list-group-item {
  border: 0 !important;
  position: relative;
  padding-left: 20px !important; 
}
.diamond-list .list-group-item:before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #0c4b85;
  position: absolute;
  left: 0;
  top: 17px;
  transform: rotate(45deg);
}
.fleet-page .threecolwithimage__unit img {
  width: 300px;
  margin-bottom: 20px;
}
.fleet-page .threecolwithimage__unit {
  padding: 30px;
}
.fleet-page .threecolwithimage__unit h4 {
  font-size: 18px;
  font-weight: 700;
}
.fleet-page .threecolwithimage__unit:first-child {
  border-right: 2px solid #e4e4e4;
  border-bottom: 2px solid #e4e4e4;
}
.fleet-page .threecolwithimage__unit:nth-child(2) {
  border-left: 2px solid #e4e4e4;
  border-bottom: 2px solid #e4e4e4;
}
.fleet-page .threecolwithimage__unit:nth-child(3) {
  border-top: 2px solid #e4e4e4;
  border-right: 2px solid #e4e4e4;
}
.fleet-page .threecolwithimage__unit:nth-child(4) {
  border-left: 2px solid #e4e4e4;
  border-top: 2px solid #e4e4e4;
}
.contact-form {
  background: #0c4b85;
  padding: 25px;
  border-radius: 15px;
}
.contact-form .btn__main {
  width: 100%;
}
.contact-form input {
  height: 55px;
}

.contact-form h3 {
  color: #fff;
  font-size: 20px !important;
  margin-bottom: 20px;
}
.contact__page a {
  color: #0c4b85;
  text-decoration: none;
  font-weight: 700;
}

.box-list-inline {
  display: block !important;
  flex-direction: row !important;
  gap: 30px;
}
.box-list-inline .list-group-item {
  width: 23%;
  margin: 15px 10px;
  float: left;
  text-align: left;
  border: 0;
  box-shadow: 0 0 10px 1px #e5e5e5;
  padding: 20px;
  border-radius: 5px !important;
  border-top: 4px solid #aa5566;
  border-top-width: 4px !important;
}
.box-list-inline a,
.box-list-inline a:hover {
  color: #0c4b85;
  text-decoration: none;
}
.list__count__2 {
  display: inline-table !important;
}
.list__count__2 .list-group-item {
  width: 50%;
  float: left;
}
.mob__nav{
  display: none; 
}
.mob__nav a{
  color: #fff;
  text-decoration: none; 
  font-size: 20px;
}

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: -250px;
  background: #101422;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  z-index: 999999999999;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #fff;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

 .close{
  color: #fff;

 }
 .mob__fleet__info{
  display: none;
 }
 .sidenav a.active{
  color: #ffe12e;;
 }
 .hp__banner label img{
  height: auto !important;
 }
 .open__nav img{
  width: 20px !important;
 }