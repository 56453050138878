@media (max-width: 1416px) {
  .hp__banner .banner__text {
    width: 38%;
  }
  .quopte__wrapper {
    width: 490px;
    top: 15%;
  }
  .hp__banner .banner__text h1 {
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 46px;
  }
  .hp__banner img {
    height: 750px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    flex: 0 0 auto;
    width: 46%;
    margin-bottom: 40px;
  }
  .threecolwithimage__wrapper .threecolwithimagehover__unit {
    width: 29%;
  }
  .homepage-more-about p {
    font-size: 14px;
    line-height: 24px;
  }
  .homepage-more-about {
    gap: 50px;
  }
  .slider__wrapper {
    width: 100%;
  }
  .app__section__with__bg h4 {
    color: #fff;
    font-size: 32px;
  }
  .app__section__with__bg {
    height: 450px;
    background-size: 100% 100%;
    padding: 10px 0;
    margin-bottom: 0;
  }
  .app__section__with__bg .text-right {
    top: -40px;
  }
  .app__section__with__bg .text-right img {
    float: right;
    height: 580px;
    object-fit: contain;
  }
  .w-70 {
    width: 100%;
  }
  .box-list-inline .list-group-item {
    width: 22%;
  }
  .custom__media__plain {
    height: 70px;
  }
  .section__title {
    font-size: 32px;
    line-height: 40px;
  }
  .threecolwithimage__wrapper .nav-tabs {
    width: 100%;
  }
  .app_wrap .mob__img {
    width: 80%;
  }
  .quopte__wrapper {
    padding: 30px;
  }
  .homepage-review .reviewer-info {
    left: 0;
    top: 0;
    display: flex;
  }
  .homepage-review .slick-slider {
    padding-left: 0px;
  }
  footer:before{
    content: none;
  }
}

@media (max-width: 1199px) {
  nav.navbar {
    display: none;
  }
  .mob__nav p {
    margin-bottom: 0 !important;
  }
  .mob__nav img {
    width: 100px;
  }
  .mob__nav {
    background: #0c111f;
    position: relative;
    z-index: 999;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
  }
  .open__nav {
    color: #fff;
    font-size: 24px;
  }
  .close__nav i {
    font-size: 24px;
    margin-right: 10px;
  }
  .close__nav {
    display: flex;
    color: #fff;
    font-size: 16px;
    padding-left: 35px;
    margin-bottom: 20px;
  }
  .hp__banner .banner__text {
    left: 40px;
  }
  .quopte__wrapper {
    right: 3%;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    flex: 0 0 auto;
    width: 44%;
  }
  .threecolwithimage__wrapper .threecolwithimagehover__unit {
    width: 28%;
  }
  .app__section__with__bg {
    margin-bottom: 0;
    height: 380px;
  }
  .app__section__with__bg h4 {
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 10px;
  }
  .app__section__with__bg a img {
    width: 150px;
  }
  .app__section__with__bg .text-right {
    top: -90px;
  }
  .section__gap.w-70 .section__title {
    font-size: 36px;
    font-weight: 600;
    line-height: 46px;
  }

  .hp__banner .banner__text {
    right: 20px;
    left: auto;
  }
  .quopte__wrapper {
    left: 5%;
  }
  .app_wrap a img {
    width: 150px;
  }

  .custom__media__plain .left__part img {
    width: 30px;
    height: 30px;
    padding: 8px;
  }
  .app_wrap .mob__img {
    width: 90%;
  }
  .threecolwithimage__wrapper .threecolwithimagehover__unit {
    width: 44%;
    padding: 20px;
  }
  .container-fluid {
    padding: 0 20px !important;
  }
}

@media (max-width: 991px) {
  .diamond-list .list-group-item { 
    background-color: #f5f5f5;
  }
  body {
    background: #f5f5f5 !important;
  }
  .hp__banner .banner__text {
    position: absolute;
    top: 150px;
    left: 40px;
    width: 90%;
    z-index: 99;
  }
  .form-check-label {
    color: #212121;
  }
  .quopte__wrapper {
    background: #fff;
    width: 100%;
    top: 0;
    border-radius: 0;
    position: relative;
    right: 0;
  }
  .quopte__wrapper .form-select,
  .quopte__wrapper .form-control {
    border: 1px solid #c7cace;
    background: #ffffff;
  }
  .quopte__wrapper .input-group-text {
    border: 1px solid #c7cace;
    background: #ffffff;
  }
  .quopte__wrapper .input-group .form-control {
    border-right: 0;
  }
  .hp__banner img {
    height: 500px;
    object-fit: cover;
  }
  .logowrapper .logolist.container-fluid {
    padding: 0 !important;
    display: block;
  }
  .logowrapper {
    padding: 0px;
    height: 500px;
  }
  .logolist .logo-wrap {
    width: 50%;
    height: 150px;
    background: #fff;
    float: left;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .logolist .logo-wrap:first-child {
    border-right: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
  .logolist .logo-wrap:nth-child(2) {
    border-left: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
  .logolist .logo-wrap:nth-child(3) {
    border-top: 2px solid #f5f5f5;
    border-right: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
  .logolist .logo-wrap:nth-child(4) {
    border-top: 2px solid #f5f5f5;
    border-left: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
  .logolist .logo-wrap:nth-child(5) {
    border-top: 2px solid #f5f5f5;
    border-right: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
  .logolist .logo-wrap:nth-child(6) {
    border-top: 2px solid #f5f5f5;
    border-left: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
  }
  .container-fluid {
    padding: 0 20px !important;
  }
  .hp__banner {
    margin-bottom: 50px;
    top: 0;
  }
  .w-60 {
    width: 100%;
  }
  .section__gap {
    padding: 60px 0;
  }
  .threecolwithimage__wrapper {
    position: static;
    margin-bottom: 80px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    flex: 0 0 auto;
    width: 45%;
    display: flex;
    flex-direction: inherit;
    justify-content: center;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 8px 2px #e4e4e4;
    border-radius: 16px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit figure {
    width: 50%;
    background: #f5f5f5 !important;
    display: flex;
    align-items: center;
    margin: 0;
    border-radius: 0 16px 16px 0;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit .fleet__info {
    width: 50%;
    text-align: left;
    padding: 20px;
  }
  .threecolwithimage__wrapper
    .threecolwithimage__unit
    .mob__fleet__info
    .fa-star {
    color: #fbb509;
    font-size: 18px;
  }
  .threecolwithimage__wrapper
    .threecolwithimage__unit
    .mob__fleet__info
    .fa-user {
    color: #181818;
    font-size: 18px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit .mob__fleet__info {
    display: flex;
    gap: 120px;
    margin-bottom: 30px;
    color: #7b7b7b;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit .rating__wrap {
    display: none;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit .price-box button {
    border-radius: 16px !important;
    width: 100%;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit h4,
  .threecolwithimage__wrapper .threecolwithimage__unit p {
    text-align: left;
    margin-bottom: 10px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit .price-box h5 {
    display: none;
  }
  .threecolwithimage__wrapper .section__title:before {
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  .threecolwithimage__wrapper .section__title {
    text-align: center;
  }
  .threecolwithimage__wrapper .threecolwithimagehover__unit {
    width: 96%;
    padding: 0;
    background: transparent;
  }
  .threecolwithimage__wrapper p {
    text-align: center;
  }
  .threecolwithimage__wrapper {
    margin-bottom: 0;
  }
  .homepage-review .section__title {
    text-align: center;
    font-size: 36px;
  }
  .app__section__with__bg h4 {
    margin-top: 0 !important;
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 10px;
  }
  .app__section__with__bg .text-right img {
    height: 330px;
    float: right;
  }
  .app__section__with__bg .text-right {
    top: -30px;
  }
  .section__gap.w-70 .section__title {
    font-size: 24px;
  }
  .homepage-review .section__title:before {
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  .app__section__with__bg {
    position: relative;
    margin-top: 350px;
  }
  .app__section__with__bg .text-right {
    position: absolute;
    left: 0;
    top: -350px;
  }
  .app__section__with__bg h4 {
    margin-top: 80px !important;
  }
  .app__section__with__bg .text-right img {
    width: 100%;
    height: 500px;
  }
  footer .pl-5 {
    padding-left: 10px !important;
  }
  footer h4 {
    margin-top: 40px;
  }
  .homepage-more-about {
    gap: 40px;
    padding: 20px;
  }
  .homepage-more-about img {
    width: 120px;
  }
  .homepage-more-about i {
    font-size: 36px;
  }
  footer .suscribe-part h3,
  footer .suscribe-part p {
    text-align: left !important;
    width: 100%;
  }
  footer .suscribe-part {
    text-align: left;
  }
  .quopte__wrapper {
    left: 0;
  }
}

@media (max-width: 767px) {
  .box-list-inline .list-group-item {
    width: 45%;
  }
  .inr__banner img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit .mob__fleet__info {
    display: flex;
    gap: 70px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    width: 95%;
  }
  .app_wrap {
    text-align: center;
  }
  .app_wrap .mob__img {
    width: 40%;
  }
  footer .copyright p {
    text-align: center;
  }
  footer .copyright {
    display: block;
    text-align: center;
  }
  .fleet-page .threecolwithimage__unit{
    text-align: center;
    padding:  20px 0;
  }
  .fleet-page .threecolwithimage__unit:first-child,
  .fleet-page .threecolwithimage__unit:nth-child(3) {
    border-right: 0;
  }
  .fleet-page .threecolwithimage__unit:nth-child(2),
  .fleet-page .threecolwithimage__unit:nth-child(4) {
    border-left: 0;
  }
}

@media (max-width: 576px) {
  .box-list-inline .list-group-item {
    width: 97%;
  }
  .section__title {
    font-size: 22px;
    line-height: 36px;
  }
  .app__section__with__bg h4,
  .threecolwithimage__wrapper .threecolwithimage__unit h4 {
    font-size: 18px;
  }
  .threecolwithimage__wrapper .btn__main {
    padding: 10px 32px !important;
  }
  .hp__banner .banner__text {
    top: 50px;
  }
  .homepage-more-about img {
    display: none;
  }
  .homepage-review .section__title {
    font-size: 28px;
  }
  .homepage-review .reviewer-info h5 {
    font-size: 18px;
  }
  .threecolwithimage__wrapper p {
    font-size: 16px;
  }
  .hp__banner img {
    height: 340px;
  }
  .custom__media__plain {
    height: auto;
  }
  .app_wrap a img {
    width: 125px;
  }
  .homepage-review .section__title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .threecolwithimage__wrapper .threecolwithimage__unit .mob__fleet__info {
    display: flex;
    gap: 30px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    display: block;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit figure,
  .threecolwithimage__wrapper .threecolwithimage__unit .fleet__info {
    width: 100%;
  }
  .hp__banner .banner__text h1 {
    margin-bottom: 15px;
    font-size: 18px;
    padding-right: 20px;
  }
  .hp__banner .banner__text p {
    font-size: 14px;
    padding-right: 20px;
  }
  .quopte__wrapper {
    padding: 0px 0px 10px 20px;
  }
  .homepage-review .reviewitem {
    padding: 20px;
  }
  .app__section__with__bg {
    height: 460px;
  }
  .quopte__wrapper .btn__main {
    width: 100%;
  }
  .logowrapper .logolist img {
    height: 30px;
    width: 60%;
    object-fit: contain;
  }
  .threecolwithimage__wrapper p {
    font-size: 16px;
  }
  .list__count__2 {
    display: flex !important;
  }
}
